// {
//     mobile: '320px',
//     largeMobile: '480px',
//     smallTablet: '576px',
//     tablet: '768px',
//     smallDesktop: '992px',
//     desktop: '1200px',
//     largeDesktop: '1600px',
// }

.large-desktop-item {
  display: block;
}

.small-desktop-item {
  display: none;
}

@media (max-width: 991px) {
  .large-desktop-item {
    display: none;
  }

  .small-desktop-item {
    display: flex;
  }
}