@import "./variables";

.theme-layout.dark {
  // COLOR
  .color-blue {
    color: @dark-color-blue !important;
  }
  .color-grey {
    color: @dark-color-light-grey1 !important;
  }

  .bg-white {
    background-color: @dark-color-dark-grey2 !important;
  }

  .ant-divider {
    border-top-color: rgb(88, 88, 88);
  }

  .ant-card {
    background-color: @dark-color-dark-grey2;
  }

  .ant-btn {
    background-color: @dark-color-dark-grey2;
    color: @color-white;
  }

  .btn-transparent {
    background-color: transparent !important;
  }

  .btn-blue {
    &.ant-btn-primary {
      background-color: @dark-color-blue;
      border-color: @dark-color-blue;
      color: white;
    }
    border-color: @dark-color-blue;
    color: @dark-color-blue;
  }

  .ant-btn-primary {
    background: @dark-color-blue;
    border-color: @dark-color-blue;
  }

  .ant-form-item-has-error :not(.ant-input-disabled).ant-input {
    background-color: @dark-color-dark-grey2;
  }
  .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper {
    background-color: @dark-color-dark-grey2;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: @dark-color-grey;
  }

  .quill {
    .ql-toolbar.ql-snow {
      border-color: @dark-color-light-grey1;
      .ql-picker, button {
        color: @dark-color-light-grey1;
      }
      .ql-stroke {
        stroke: @dark-color-light-grey1;
      }
    }
    .ql-container.ql-snow {
      border-color: @dark-color-light-grey1;
      color: @dark-color-light-grey1;
    }
  }

  .ant-input-password-icon {
    color: @dark-color-light-grey1;
  }
}

.dark {
  .ant-dropdown-menu {
    background-color: @dark-color-dark-grey2;
    .ant-dropdown-menu-item {
      color: @dark-color-light-grey1;
      &:hover {
        background-color: @dark-color-dark-grey1;
      }
    }
    .ant-dropdown-menu-item-selected {
      background-color: @dark-color-dark-grey1;
      color: @dark-color-blue;
    }
    .ant-dropdown-menu-item-divider {
      background-color: #e8e8e8;
    }
  }

  .color-grey {
    color: @dark-color-light-grey1 !important;
  }
}
.modal-dialog.dark {
  .modal-body {
    background-color: @dark-color-dark-grey2;
  }
}